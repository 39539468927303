<template>
  <a-card title="创建谱例歌曲">
    <template #extra><a @click="handleBack">返回</a></template>
    <sheet-song-base-form
      :actionType="FormActionType.CREATE"
      :current="{}"
      @onOk="handleCreate"
    ></sheet-song-base-form>
  </a-card>
</template>

<script>
import SheetSongBaseForm from './SheetSongBaseForm.vue'
import { useRouter, useRoute } from 'vue-router'
import {
  createOfficialSheetSong,
  setOfficialSheetSongTags,
  createPersonalSheetSong,
  setPersonalSheetSongTags
} from '@/services/songs'
import { message } from 'ant-design-vue'
import { FormActionType } from './config'
import { ref } from 'vue'
export default {
  components: { SheetSongBaseForm },
  setup () {
    // router.params.type
    const router = useRouter()
    const route = useRoute()

    const loading = ref(false)

    const handleBack = () => {
      history.go(-1)
    }

    const handleCreate = async (params, tags) => {
      let isSuccess = false
      let id
      try {
        loading.value = true
        const createService = route.params.type === '1' ? createOfficialSheetSong : createPersonalSheetSong
        const data = await createService(params)
        id = data.id
        isSuccess = true
      } catch (error) {
        console.log(error)
        message.error('创建歌曲失败')
        loading.value = false
      }
      if (!isSuccess) {
        return
      }
      try {
        const setTagService = route.params.type === '1' ? setOfficialSheetSongTags : setPersonalSheetSongTags
        await setTagService(id, { tags })
        isSuccess = true
      } catch (error) {
        isSuccess = false
        message.error('设置歌曲标签失败,请重新设置')
        loading.value = false
        handleBack()
      }
      if (isSuccess) {
        message.success('保存成功')
        loading.value = false
        handleBack()
      }
    }
    return {
      FormActionType,
      handleBack,
      handleCreate
    }
  }
}
</script>

<style>
</style>
